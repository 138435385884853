(function() {
    angular.module('EntrakV5').controller('deviceLocationController', deviceLocationController);

    function deviceLocationController($scope, $rootScope, $timeout, Service, Api, KEY, APIKEY, $state, $stateParams) {
        console.log('deviceLocationController');

        //$stateParams.tenantId
        //$stateParams.floorId
        var caller = Api.createApiCaller();
        $scope.btnStatus = {};

        $rootScope.hideTabs = true;

        $scope.floorPlan = null;

        $scope.fanSpeedProfiles = [];

        //init breadcrumbs
        $scope.steps = [ 
            $rootScope.STEP1, {
                name: '-',
                navigate: function(){
                    $state.go("tenant", { tenantId: $stateParams.tenantId });
                }
            }, {
                name: '-',
                navigate: function(){
                    $state.go("floor", { floorId: $stateParams.floorId, tenantId: $stateParams.tenantId });
                }
            }, {
                name: Service.translate("tenant.editLocationTab")
            }
        ];

    /* first load */
        //update breadcrumbs display and get floor
        $rootScope.loadingPage = 0;
        $rootScope.getLandlordId().then(function(id){
            $rootScope.loadingPage++;
            $rootScope.updateTenantStep2Name($scope.steps, $stateParams.tenantId, $stateParams.floorId, true, caller, true).then(function(res){
                $scope.floorPlan = res.floorPlan;
                $rootScope.processGwForDeviceMap(res.gateways, $stateParams.floorId);

                var tmpMap = {};
                for (var key in APIKEY.applicationType){
                    tmpMap[APIKEY.applicationType[key]] = [];
                }
                var unknownArr = tmpMap[APIKEY.applicationType.unknown];
                var serialSorter = Service.getSorter('serialId');
                res.gateways.sort(serialSorter).forEach(function(g){
                    g.devices.sort(serialSorter).forEach(function(d){
                        d.gateway = g;
                        (tmpMap[d.applicationType] || unknownArr).push(d); //in case have other type or null, also push in unknown type arr
                    });
                });
                tmpMap[APIKEY.applicationType.gateway] = res.gateways.slice();
                if (unknownArr.length)
                    console.error("Unknown application type found", unknownArr);
                $scope.editLocationData.markers = Object.keys(tmpMap).sort().reduce(function(arr, type){
                    return arr.concat(tmpMap[type]);
                }, []);
                $scope.editLocationData.markerIndexMap = {};
                $scope.editLocationData.markers.forEach(function(item, i){
                    $scope.editLocationData.markerIndexMap[item.id] = i;
                });
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                console.error('invalid floor/tenant id');
                $rootScope.loadingPage--;
                alert(Service.translate("error.generalGetDataFail"));
            });
        });
        $rootScope.loadingPage++;
        caller.call(Api.getFanSpeedProfiles()).then(function(res){
            $scope.fanSpeedProfiles = res;
            $rootScope.loadingPage--;
        }, function(err){
            if (err === KEY.ignore)
                return;
            console.error('cannot get fanSpeedProfiles', err);
            $rootScope.loadingPage--;
            alert(Service.translate("error.generalGetDataFail"));
        });
    /* first load */

    /* step3 (isEditLocation) */
        $scope.editLocationData = {
            expandedDeviceId: null,
            searchText: ''
        }
        for (var type in APIKEY.applicationType){
            if (APIKEY.applicationType.hasOwnProperty(type)){
                $scope.editLocationData[APIKEY.applicationType[type]] = {
                    expanded: true,
                    name: Service.translate("applicationType." + type)
                }
            }
        }

        $scope.locFilter = function(){
            return function(d){
                return d.location == null;
            }
        }

        $scope.searchFilter = function(){
            return function(d){
                var text = $scope.editLocationData.searchText.trim().toUpperCase();
                if (text){
                    return d.serialId.toUpperCase().indexOf(text) != -1 || (d.gateway && d.gateway.serialId.toUpperCase().indexOf(text) != -1);
                } else {
                    return true;
                }
            }
        }

        $scope.expandDeviceOptions = function(d){
            if (d.applicationType === APIKEY.applicationType.gateway)
                return;
            $scope.editLocationData.expandedDeviceId = d.id;
        }

        $scope.turnOnOffDevice = function(d, isOn){
            caller.call(Api.performDeviceAction(d.id, isOn ? APIKEY.action.on : APIKEY.action.off)).then(null, function(err){
                if (err === KEY.ignore)
                    return;
                alert(Service.translate("error.generalControlFail"));
            });
        }

        $scope.blinkLight = function(d){
            if ($rootScope.canBlinkType(d.applicationType)){
                caller.call(Api.performDeviceAction(d.id, APIKEY.action.blink)).then(function(res){
                    d.blinking = true;
                    $timeout(function(){
                        d.blinking = false;
                    }, 2000);
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    alert(Service.translate("error.generalControlFail"));
                });
            }
        }

        $scope.deviceDragStart = function($i){
            var d = $scope.editLocationData.markers[$i];
            if (d.location){
                $scope.originLoc = { id: d.location.id, x: d.location.x, y: d.location.y };
            } else {
                $scope.originLoc = null;
            }
        }

        $scope.dragOpt = {
            autoScroll: true,
            distance: 1,
            filter: ".deviceMarker:not(.noDrag)",
            cursorOffset: { top: 0, left: 0 },
            hint: function(e) {
                return $('<div class="hint ' + e.attr("class") + '" style="transform: translate( -50%, -50%)"></div>');
            },
            dragstart: function(e) {
                e.currentTarget.hide();
                $scope.deviceDragStart(e.currentTarget.data("index"));
            },
            dragend: function(e) {
                e.currentTarget.show();
            }
        }

        $scope.dropDevice = function($i){
            var d = $scope.editLocationData.markers[$i];
            var isGw = d.applicationType === APIKEY.applicationType.gateway;
            d.cssClass = 'noDrag';
            d._originLoc = $scope.originLoc;
            d.location.x = Math.round(d.location.x);
            d.location.y = Math.round(d.location.y);
            var success = function(res){
                if (isGw) {
                    res = {gatewayLocations: res};
                    $rootScope.processGwForDeviceMap(res, $stateParams.floorId);
                    d.location = res.location;
                } else {
                    d.location = res;
                }
                delete d._originLoc;
                delete d.cssClass;
                $rootScope.loadingPage--;
            }
            var failure = function(err){
                if (err === KEY.ignore)
                    return;

                if (d._originLoc){
                    d.location.x = d._originLoc.x;
                    d.location.y = d._originLoc.y;
                } else {
                    d.location = null;
                }
                delete d._originLoc;
                delete d.cssClass;
                $rootScope.loadingPage--;
                alert(Service.translate(d.applicationType === APIKEY.applicationType.gateway ? "error.generalUpdateGateway" : "error.generalUpdateDevice"));
            }

            $rootScope.loadingPage++;
            if (isGw) {
                Api.setGatewayLoc(d.id, $stateParams.floorId, d.location.x, d.location.y).then(success, failure);
            } else {
                if (d._originLoc){
                    caller.call(Api.updateDeviceLoc(d.location.id, d.location.x, d.location.y)).then(success, failure);
                } else {
                    caller.call(Api.createDeviceLoc(d.id, $stateParams.floorId, d.location.x, d.location.y)).then(success, failure);
                }
            }
        }

        $scope.mapPopupOpt = {
            anchor: $("#tenant #deviceLocationMap .tooltipAnchor div"),
            origin: "top right",
            position: "top left",
        }

        $scope.showMapPopup = function($i){
            $scope.mapPopup.close();
            $scope.mapPopup.setOptions({anchor: $("#tenant #deviceLocationMap .tooltipAnchor div")});
            setTimeout(function(){    //wait after popup closed
                $scope.mapPopup.open();
            }, 200);

            $scope.hoveredMarker = $scope.editLocationData.markers[$i];
        }

        $scope.removeDeviceFromFloor = function(d){
            $rootScope.loadingPage++;
            var isGw = d.applicationType === APIKEY.applicationType.gateway;
            d.cssClass = 'noDrag';
            caller.call(isGw ? Api.deleteGatewayLoc(d.id, $stateParams.floorId) : Api.deleteDeviceLoc(d.location.id)).then(function(res){
                d.location = null;
                delete d.cssClass;
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                delete d.cssClass;
                $rootScope.loadingPage--;
                alert(Service.translate("error.generalDeleteFail"));
            });
            $scope.mapPopup.close();
        }

        $scope.confirmUnpairDevice = function(){
            $rootScope.loadingPage++;
            var d = $scope.selectedDeleteItem;
            d.cssClass = 'noDrag';
            caller.call(Api.unpairDevice($scope.selectedDeleteItem.id)).then(function(res){
                Service.deleteArrItem($scope.editLocationData.markers, res);
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                delete d.cssClass;
                $rootScope.loadingPage--;
                alert(Service.translate("error.generalUnpairDevice"));
            });
            $rootScope.deletePopup.close();
        }
        $scope.unpairDevice = function(d){
            $scope.selectedDeleteItem = d;
            $rootScope.deletePopup.show("tenant.popup.unpairDevice", "tenant.popup.unpairDeviceDesc", d.serialId, $scope.confirmUnpairDevice);
            $scope.mapPopup.close();
        }

        $scope.editLocationDeviceWinData = {};
        $scope.editLocationDeviceWinOpt = {
            title: Service.translate("tenant.popup.editDevice"),
            width: "740px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            open: function(){
                $scope.$apply(function(){
                    $scope.btnStatus.saving = false;
                });
            }
        }
        $scope.confirmEditLocationDevice = function(){
            $scope.btnStatus.saving = true;
            var id = $scope.editLocationDeviceWinData.device.id;
            if ($scope.editLocationDeviceWinData.applicationType === APIKEY.applicationType.aircon){
                var defaultTemp = $scope.editLocationDeviceWinData.defaultTemp;
                var fanSpeedProfileId = $scope.editLocationDeviceWinData.fanSpeedProfileId;
                var fanSpeed = $scope.editLocationDeviceWinData.defaultFanSpeed;
            } else {
                var defaultTemp = $scope.editLocationDeviceWinData.device.defaultValue.defaultTemperature;
                var fanSpeedProfileId = $scope.editLocationDeviceWinData.device.fanSpeedProfileId;
                var fanSpeed = $scope.editLocationDeviceWinData.device.defaultFanSpeed;
            }
            var defaultDim = $scope.editLocationDeviceWinData.device.dimmable ? $scope.editLocationDeviceWinData.defaultDimLv : $scope.editLocationDeviceWinData.device.defaultValue.defaultDimming;
            var remark = $scope.editLocationDeviceWinData.remark.trim();
            //remark: updateDeviceInfo cannot return all value (no location), dont use the response object
            caller.call(Api.updateDeviceInfo(id, $scope.editLocationDeviceWinData.applicationType, defaultTemp, defaultDim, fanSpeed, fanSpeedProfileId, remark, $stateParams.floorId)).then(function(res){
                $scope.editLocationDeviceWin.close();
                var d = Service.replaceArrItem($scope.editLocationData.markers, res);
            }, function(err){
                if (err === KEY.ignore)
                    return;
                alert(Service.translate("error.generalUpdateDevice"));
            });
        }
        $scope.editLocationDevice = function(d){
            if (d.applicationType === APIKEY.applicationType.gateway)
                return;
            $scope.editLocationDeviceWinData.device = d;
            $scope.editLocationDeviceWinData.remark = d.remark || '';
            $scope.editLocationDeviceWinData.applicationType = d.applicationType;
            $scope.editLocationDeviceWinData.defaultTemp = d.defaultValue.defaultTemperature ? parseFloat(d.defaultValue.defaultTemperature) : '';
            $scope.editLocationDeviceWinData.defaultDimLv = d.defaultValue.defaultDimming ? parseInt(d.defaultValue.defaultDimming) : 0;
            $scope.editLocationDeviceWinData.defaultFanSpeed = d.defaultFanSpeed;
            $scope.fanSpeedProfileDropdown.setDataSource(new kendo.data.DataSource({
                data: $scope.fanSpeedProfiles.filter(function(p){
                    return p.deviceType === d.deviceType && p.provider === d.provider;
                })
            }));
            $scope.editLocationDeviceWinData.fanSpeedProfileId = d.fanSpeedProfileId;
            setTimeout(function(){
                $scope.editLocationDeviceWin.open().center();
            });
        }
        $scope.isValidFanSpeed = function(){
            return $scope.editLocationDeviceWinData.applicationType !== APIKEY.applicationType.aircon || $scope.editLocationDeviceWinData.fanSpeedProfileId != null && $scope.editLocationDeviceWinData.fanSpeedProfileId != "00000000-0000-0000-0000-000000000000" && $scope.editLocationDeviceWinData.defaultFanSpeed;
        }

        $scope.applicationTypeDropdownOpt = {
            dataSource: Object.keys(APIKEY.applicationTypeInv).filter(function(t){
                return t !== APIKEY.applicationType.unknown && t !== APIKEY.applicationType.gateway;
            }).map(function(t){
                return {
                    text: Service.translate("applicationType." + APIKEY.applicationTypeInv[t]),
                    value: t,
                }
            }),
            dataTextField: "text",
            dataValueField: "value"
        }

        $scope.fanSpeedProfileDropdownOpt = {
            dataSource: [],
            dataTextField: "description",
            dataValueField: "id"
        }
        $scope.fanSpeedDropdownOpt = {
            dataSource: [APIKEY.fanSpeed.auto, APIKEY.fanSpeed.high, APIKEY.fanSpeed.medium, APIKEY.fanSpeed.low].map(function(v){
                return { text: Service.translate("label." +　v), value: v };
            }),
            dataTextField: "text",
            dataValueField: "value"
        }

        $scope.isValidApplicationType = function(){
            return $scope.editLocationDeviceWinData.applicationType !== APIKEY.applicationType.unknown && APIKEY.applicationTypeInv[$scope.editLocationDeviceWinData.applicationType];
        }
    /* step3 (isEditLocation) */

        $scope.$on('$destroy', function() {
            console.log("deviceLocationController destroy");
            caller.cancel();
        });
    }
})();
